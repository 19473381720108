<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')"/>
    <div class="container flex justify-center items-center ctlModal">
      <div class="content ctlModal2">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <p class="title">Meus boletos</p>
        <div class="w-full overflow-x-auto lg:overflow-x-hidden ">
          <vuetable ref="vuetable"
            class="boletos_table"
            :fields="header"
            :api-mode="false"
            :data-manager="dataManager"
            :no-data-template="loading ? '' : 'Você ainda não fez nenhuma solicitação de depósito.'"
          >
            <!--<template v-slot:data="props"  class="cell hidden md:block">
              {{$moment(props.rowData.data).format('DD/MM/YYYY')}}
            </template>-->
            <template v-slot:dataVencimento="props">
              {{$moment(props.rowData.dataVencimento).format('DD/MM/YYYY')}}
            </template>
            <template v-slot:dataPagamento="props">
              {{props.rowData.dataPagamento ? $moment(props.rowData.dataPagamento).format('DD/MM/YYYY') : '-'}}
            </template>
            <template v-slot:valor="props">
              {{props.rowData.valor | money}}
            </template>
            <template v-slot:status="props">
              <p>{{getStatus(props.rowData.status)}}</p>
            </template>
            <template v-slot:actions="props">
              <i class="fas fa-external-link-alt text-secondary cursor-pointer" @click="openBoleto(props.rowData.url)"></i>
            </template>
          </vuetable>
        </div>
        <div v-if="loading" class="h-full w-full flex justify-center items-center">
          <i class="fas fa-spinner fa-spin text-2xl text-secondary"></i>
        </div>
        <div class="flex justify-end">
          <vuetable-pagination
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
            :css="paginationCss"
          ></vuetable-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VuetablePagination } from 'vuetable-2'
import moneyFilter from '@/mixins/moneyFilter'

import Wallet from '@/services/Investor/Wallet/Wallet'

export default {
  name: 'modal-boletos',
  mixins: [moneyFilter],
  components: { VuetablePagination },
  data () {
    return {
      loading: true,
      boletos: [],
      pagination: {
        current_page: 0,
        last_page: 0,
        per_page: 15,
        total: 0
      },
      header: [
       /* {
          name: '__slot:data',
          title: 'Data',
          width: '15%'
        },*/
        {
          name: '__slot:dataVencimento',
          title: 'Vencimento',
          width: '15%'
        },
        /*{
          name: '__slot:dataPagamento',
          title: 'Pagamento',
          width: '15%',
          cssClass: 'cell hidden md:block'
        },*/
        {
          name: '__slot:valor',
          title: 'Valor',
          width: '25%'
        },
        {
          name: '__slot:status',
          title: 'Status',
          width: '20%'
        },
        {
          name: '__slot:actions',
          title: '',
          width: '10%'
        }
      ],
      paginationCss: {
        wrapperClass: 'border rounded-lg flex m-2 text-secondary',
        activeClass: 'text-primary shadow-inner bg-gray-100 font-bold',
        disabledClass: 'text-gray-300 cursor-not-allowed',
        pageClass: 'pagination_page_class',
        linkClass: 'pagination_page_class',
        icons: {
          first: 'fas fa-angle-double-left',
          prev: 'fas fa-angle-left',
          next: 'fas fa-angle-right',
          last: 'fas fa-angle-double-right'
        }
      }
    }
  },
  methods: {
    getStatus(str){
      if(str == 'expirado') return 'Vencido';
       if(str == 'pago') return 'Pago';
       if(str == 'pendente') return  'Pendente';

    },
    openBoleto (url) {
      window.open(url, '_blank')
    },
    onChangePage (page) {
      let reload = false
      switch (true) {
        case page === 'next':
          if (!this.$refs.pagination.isOnLastPage) {
            this.pagination.current_page = this.pagination.current_page + 1
            reload = true
          }
          break
        case page === 'prev':
          if (!this.$refs.pagination.isOnFirstPage) {
            this.pagination.current_page = this.pagination.current_page - 1
            reload = true
          }
          break
        default:
          if (this.pagination.current_page !== page) {
            this.pagination.current_page = page
            reload = true
          }
          break
      }
      if (reload) {
        this.$refs.vuetable.reload()
      }
    },
    dataManager (sortOrder, pagination) {
      if (this.boletos.length < 1) return

      const from = (this.pagination.current_page - 1) * this.pagination.per_page
      const to = from + this.pagination.per_page

      return {
        pagination: this.pagination,
        data: this.boletos.slice(from, to)
      }
    }
  },
  async mounted () {
    try {
      this.loading = true
      this.boletos = (await Wallet.getBoletos()).data.data
      this.pagination = {
        current_page: 1,
        last_page: Math.ceil(this.boletos.length / this.pagination.per_page),
        total: this.boletos.length,
        per_page: this.pagination.per_page
      }
      this.$refs.vuetable.reload()
      this.$refs.pagination.setPaginationData(this.pagination)
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="sass" scoped>

.ctlModal
  position: absolute
  padding: 0
  @screen lg
    margin-top: 0

.ctlModal > div
    padding: 0
    padding-top: 16px
    @screen md
      @apply p-4

.ctlModal2
  height: 100vh
  @screen lg
    height: auto

.ctlModal3
  height: 100%
  @screen lg
    height: auto

.modal
  @apply fixed flex items-center justify-center w-screen h-screen top-0 left-0 z-50
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)
  box-sizing: border-box
  border-width: 0
  border-style: solid
  border-color: #e2e8f0

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full overflow-y-auto bg-white z-50 flex flex-col pt-4 px-4
  @screen lg
    @apply w-3/4 rounded-lg h-160

.title
  @apply text-secondary font-bold mb-2 text-center mt-2
  @screen lg
    @apply text-left text-lg

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

::-webkit-scrollbar
  @screen md
    width: 8px
  @screen lg
    width: 8px

::-webkit-scrollbar-track
  @screen md
    @apply bg-gray-400 rounded-lg
  @screen lg
    @apply bg-gray-400 rounded-lg

::-webkit-scrollbar-thumb
  @screen md
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
  @screen lg
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
</style>

<style lang="sass">
.boletos_table
  @apply w-full bg-white shadow rounded-lg
  thead tr
    @apply rounded-t-lg
  tbody
    @apply rounded-b-lg
  tr
    &:nth-child(even)
      @apply bg-gray-100
    &:last-child
      @apply rounded-b-lg
      td:first-child
        @apply rounded-bl-lg
      td:last-child
        @apply rounded-br-lg
    th
      @apply p-2 text-sm text-gray-600
      background: #ECECEC
    td
      @apply relative text-gray-600 text-sm border border-gray-300 py-1 text-center

.pagination_page_class
  @apply h-8 w-8 border-l flex justify-center items-center cursor-pointer
  &:first-of-type
    @apply border-l-0 rounded-l-lg
  &:last-of-type
    @apply rounded-r-lg
  &:hover
    @apply bg-gray-100
    &.cursor-not-allowed
      @apply bg-transparent
</style>
