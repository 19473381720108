<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')"/>
    <div class="container flex justify-center items-center">
      <div class="content" v-if="!submitted">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <p class="title">Saque</p>
        <p class="subtitle">Transfira dinheiro da Carteira para sua Conta Bancária</p>
        <div class="info">
          <div class="info_left">
            <p>Saldo e Saques Disponíveis</p>
            <p>Saldo disponível: {{$store.state.investor.balance | money}}</p>
            <p>Saques realizados este mês: {{info.saquesRealizadosMes}}</p>
            <p>Saques gratuitos restantes este mês: {{`${info.saquesGratuitosRestantes} de ${info.saquesGratuitosEsteMes}`}}</p>
            <p>Tarifa de saques adicionais: {{info.tarifaSaquesAdicionais | money}}</p>
          </div>
          <div v-if="bankAccount" class="info_right">
            <p>Conta Bancária Verificada</p>
            <p>Banco: {{bankAccount.banco}}</p>
            <p>Agência: {{bankAccount.agencia}}</p>
            <p>Conta: {{bankAccount.conta}}</p>
            <p>Tipo Conta: {{bankAccount.tipoConta}}</p>
          </div>
          <div v-else class="info_right">
            <p>Não há conta bancária verificada.</p>
          </div>
        </div>
        <div class="withdrawal">
          <div class="w-full mt-2 lg:mt-0 lg:pr-4">
            <v-input type='money' name="value" v-model="value" label="Quanto você deseja sacar?" v-validate="'required|decimal:2|min_value:5'">
              <div slot="hint" class="text-right">
                <p :id="errors.first('value') ? 'error' : ''">Valor mínimo: R$ 5,00</p>
              </div>
            </v-input>
          </div>
          <div>
            <v-button class="flex justify-center mb-2" size="sm" :disabled="!bankAccount" :loading="loading" @click="submit">Sacar</v-button>
          </div>
        </div>
      </div>
      <div class="content" v-else>
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <p class="title">Confirma o saque no valor de {{value | money}}</p>
        <div class="text-center">
          <p class="subtitle">O saque será feito para:</p>
          <p class="subtitle">Banco: {{bankAccount.banco}}</p>
          <p class="subtitle">Agência: {{bankAccount.agencia}}</p>
          <p class="subtitle">Conta {{bankAccount.tipoConta}}: {{bankAccount.conta}}</p>
        </div>
        <div class="withdrawal">
          <v-button class="w-full flex justify-center lg:justify-end lg:pr-1" color="error" @click="submitted = false" :loading="loading">Voltar</v-button>
          <v-button class="w-full flex justify-center lg:justify-start lg:pl-1" @click="withdrawal" :disabled="!bankAccount" :loading="loading">Sacar</v-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VInput from '@/components/Inputs/Input'
import VButton from '@/components/Buttons/Button'

import Transactions from '@/services/Investor/Wallet/Transactions'
import MoneyFilter from '@/mixins/moneyFilter'

export default {
  name: 'withdrawal-modal',
  components: { VInput, VButton },
  mixins: [MoneyFilter],
  data () {
    return {
      info: {
        saquesGratuitosEsteMes: 0,
        saquesGratuitosRestantes: 0,
        saquesRealizadosMes: 0,
        tarifaSaquesAdicionais: 0
      },
      bankAccount: null,
      value: '',
      submitted: false,
      loading: false
    }
  },
  methods: {
    async submit () {
      if (await this.$validator.validateAll()) {
        this.submitted = true
      }
    },
    async withdrawal () {
      this.loading = true
      try {
        await Transactions.withdrawal({ valor: this.value })
        this.$store.dispatch('notification', { type: 'success', message: 'Saque solicitado com sucesso!' })
      } catch (error) {
        console.error(error)
        const err = error.response.data.erros
       this.$store.dispatch('notification', { type: 'error', message: err ? Object.values(err)[0] : 'Houve algum erro. Tente novamente.' })
        //this.$store.dispatch('notificationErrors', err)



      } finally {
        this.$emit('closeModal')
      }
    }
  },
  async mounted () {
    try {
      this.loading = true
      this.info = (await Transactions.infoWithdrawal()).data.data
      this.bankAccount = (await Transactions.getApprovedBankAccount()).data.data
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="sass" scoped>

.modal
  @apply fixed flex items-center justify-center w-screen h-screen top-0 left-0 z-50

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full bg-white z-50 flex flex-col justify-center rounded-lg pt-4 px-4
  @screen lg
    @apply w-2/3

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.title
  @apply text-secondary font-bold mb-2
  @screen lg
    @apply text-lg

.subtitle
  @apply text-xs opacity-75
  @screen lg
    @apply text-sm

.info
  @apply border border-secondary rounded flex flex-col my-2 p-2 text-sm text-secondary opacity-75
  background-color: rgba(72, 27, 144, 0.2)
  @screen md
    @apply flex-row
  &_left
    @apply w-full
    @screen md
      @apply w-1/2 pr-2
    p:first-of-type
      @apply text-base font-bold
  &_right
    @apply w-full mt-2
    @screen md
      @apply w-1/2 mt-0
    p:first-of-type
      @apply text-base font-bold

.withdrawal
  @apply flex flex-col
  @screen md
      @apply flex-row mt-2 mb-4

</style>
