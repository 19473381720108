<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')"/>
    <div class="container flex justify-center items-center">
      <div class="content" v-if="!submitted">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <p class="title">Depósito</p>
        <p class="subtitle">Adicione dinheiro na sua Carteira para participar das próximas rodadas de investimentos</p>
        <div class="deposit">
          <div class="w-full mb-8 lg:pr-2 lg:mb-2">
            <v-input type="money" name="value" v-model="value" label="Quanto você deseja depositar?" v-validate="'required|decimal:2|min_value:100|max_value:30000'">
              <div slot="hint" class="flex flex-col lg:flex-row justify-between">
                <p>Fique tranquilo, não cobramos nenhuma taxa de depósito</p>
                <p :id="errors.first('value') ? 'error' : ''">Valor mínimo: R$ 100,00 - Máximo: R$ 30.000,00</p>
              </div>
            </v-input>
          </div>
          <div>
            <v-button class="flex justify-center mb-2 lg:mb-0" size="sm" @click="submit">Criar depósito</v-button>
          </div>
        </div>
      </div>
      <div class="content" v-else>
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <p class="title">Confirma o depósito no valor de {{value | money}}?</p>
        <div class="text-center">
          <p class="subtitle">Será gerado uma fatura no valor informado com data de vencimento para o próximo dia útil.
            <br />
             Boletos podem levar até 3 dias úteis para ser compensados. Pagamentos via PIX a compensação ocorre em minutos.</p>
        </div>
        <div class="deposit">
          <v-button class="w-full flex justify-center lg:justify-end lg:pr-1" color="error" @click="submitted = false" :loading="loading">Voltar</v-button>
          <v-button class="w-full flex justify-center lg:justify-start lg:pl-1" @click="generateBoleto" :loading="loading">Gerar fatura</v-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VInput from '@/components/Inputs/Input'
import VButton from '@/components/Buttons/Button'

import Transactions from '@/services/Investor/Wallet/Transactions'
import MoneyFilter from '@/mixins/moneyFilter'

export default {
  name: 'deposit-modal',
  components: { VInput, VButton },
  mixins: [MoneyFilter],
  data () {
    return {
      value: '',
      loading: false,
      submitted: false
    }
  },
  methods: {
    async submit () {
      if (await this.$validator.validateAll()) {
        this.submitted = true
      }
    },
    async generateBoleto () {
      this.loading = true
      try {
        const response = (await Transactions.generateBoleto({ valor: this.value })).data.data
       // this.$store.dispatch('notification', { type: 'success', message: 'Fatura gerado com sucesso!' })

        this.$emit('setInvoice', response)

      } catch (error) {
        console.error(error)
        this.$store.dispatch('notification', { type: 'error', message: 'Houve algum erro. Tente novamente.' })
      } finally {
        this.$emit('closeModal')
      }
    }
  }
}
</script>

<style lang="sass" scoped>

.modal
  @apply fixed flex items-center justify-center w-screen h-screen top-0 left-0 z-50

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full bg-white z-50 flex flex-col justify-center rounded-lg pt-4 px-4
  @screen lg
    @apply w-3/4

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.title
  @apply text-secondary font-bold mb-2
  @screen lg
    @apply text-lg

.subtitle
  @apply text-xs opacity-75
  @screen lg
    @apply text-sm

.deposit
  @apply flex flex-col mt-2
  @screen md
      @apply flex-row mt-4

</style>
