import api from '@/plugins/axios'

const base = 'v1/investidor/wallet/'

export default {
  getBalance () {
    return api.get(`${base}saldo`)
  },
  getBlockedBalance () {
    return api.get(`${base}saldo-bloqueado`)
  },
  getTransactionsByRange (range) {
    return api.get(`${base}transacoes`, { params: { dataInicio: range.start, dataFim: range.end } })
  },
  getTransactionsByDays (days) {
    return api.get(`${base}transacoes-dias`, { params: { dias: days } })
  },
  getBoletos () {
    return api.get(`${base}boletos`)
  }
}
