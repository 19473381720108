<template>
  <transition name="fade">
    <div class="modal">
      <div class="backdrop" @click="$emit('closeModal')"/>
      <div class="container flex justify-center items-center ctlModal">
        <div class="content ctlModal2" v-if="!submitted">
          <p class="close" @click="$emit('closeModal')">
            <i class="fas fa-times"></i>
          </p>
          <p class="title">Visualização de Boleto/PIX</p>
          <div class="container ctlModal3"  >
                              <div class="col-12 text-center" v-if="invoice.urlQrCodePix">
                                  <img style="max-width: 75%; margin: 0 auto;" :src="invoice.urlQrCodePix" alt="Carregando QR Code PIX" title="QR Code PIX" /> <br />
                                  <b>PIX Copia & Cola</b> <br />
                                  <input type="text" readonly v-model="invoice.textQrCodePix" id="textPix" /> <br /><a href="javascript://" v-on:click="copiarPix">Copiar</a>
                              </div>

                              <div class="col-12 text-center" v-if="invoice.urlCodigoBarras">

                                  <hr style="margin-top: 8px; margin-bottom: 8px;"/>
                                  <b>Boleto Bancário</b><br />
                                  <img style="max-width: 100%" :src="invoice.urlCodigoBarras" />
                                  <small>{{invoice.linhaDigitavel}}</small>
                              </div>

                              <br />
                              <hr />
                              <br />

                              <div class="col-12 text-right">
                              <v-button  class="w-full flex justify-center" v-on:click="open(invoice.urlInvoice != null ? invoice.urlInvoice : invoice.url)">Ver fatura completa</v-button>
                              </div>
                          </div>
      </div>
    </div>
  </div>
  </transition>
</template>

<script>
import VButton from '@/components/Buttons/Button'
import MoneyFilter from '@/mixins/moneyFilter'

export default {
  name: 'invoice-modal',
  components: { VButton },
  mixins: [MoneyFilter],
  props: ['invoice'],

  data () {
    return {
      submitted: false,
      loading: false
    }
  },
  methods: {
    open(urlInvoice){
window.open(urlInvoice)
    },
                 copiarPix: function (e) {
                    document.querySelector('#textPix').select()
                    document.execCommand("copy");
                    e.target.innerText = 'Copiado!'
                },
  },

}
</script>

<style lang="sass" scoped>

.ctlModal
  position: absolute
  padding: 0
  @screen lg
    margin-top: 0

.ctlModal > div
    padding: 0
    @screen md
      @apply p-4

.ctlModal2
  height: 100vh
  @screen lg
    height: auto

.ctlModal3
  height: 100%
  @screen lg
    height: auto

.modal
  @apply fixed flex items-center justify-center w-screen h-screen top-0 left-0 z-50
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)
  box-sizing: border-box
  border-width: 0
  border-style: solid
  border-color: #e2e8f0

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full max-h-screen overflow-y-auto bg-white z-50 flex flex-col rounded-lg pt-4
  @screen lg
    @apply w-11/12 px-4
    min-height: 40rem

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.title
  @apply text-secondary font-bold mb-2 text-center mt-2
  @screen lg
    @apply text-left text-lg

.subtitle
  @apply text-xs opacity-75
  @screen lg
    @apply text-sm
</style>
