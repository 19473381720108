import api from '@/plugins/axios'

const base = 'v1/investidor/wallet/'

export default {
  getApprovedBankAccount () {
    return api.get('v1/investidor/conta-bancaria-aprovada')
  },
  infoWithdrawal () {
    return api.get(`${base}infos-saque`)
  },
  withdrawal (data) {
    return api.post(`${base}sacar`, data)
  },
  generateBoleto (data) {
    return api.post(`${base}gerar-boleto`, data)
  }
}
