<template>
  <div class="main-content">
    <invoice-modal v-if="showInvoiceModal" :invoice="invoice" @closeModal="showInvoiceModal = false" />

    <withdrawal-modal v-if="contaIuguAtiva && modalWithdrawal" @closeModal="modalWithdrawal = false" />
    <deposit-modal v-if="contaIuguAtiva && modalDeposit" @closeModal="modalDeposit = false" @setInvoice="setInvoice" />
    <bank-account-not-approved-modal v-if="!contaIuguAtiva && (modalDeposit || modalWithdrawal)"
      @closeModal="modalDeposit = false; modalWithdrawal = false" />

    <div class="flex  pl-4 mb-4">
      <div class="flex-col  w-10/12 justify-between md:justify-start items-center flex-wrap">
        <p class="w-full lg:w-auto text-sm text-gray-600 mb-2 lg:mr-4 lg:mb-0">Selecione o período:</p>
        <button @click="getTransactions(7)" class="button" :class="btnActived === 7 ? 'button_active' : ''">7
          dias</button>
        <button @click="getTransactions(15)" class="button" :class="btnActived === 15 ? 'button_active' : ''">15
          dias</button>
        <button @click="getTransactions(30)" class="button" :class="btnActived === 30 ? 'button_active' : ''">30
          dias</button>
        <v-date-picker mode="range" v-model="dates" @input="getTransactions()" :max-date="new Date()"
          :show-day-popover="false" tint-color="#5316CD" style="display: inline">
          <button class="button_calendar" :class="btnActived === 99 ? 'button_calendar_active' : ''">
            <i class="fas fa-calendar-alt"></i>
          </button>
        </v-date-picker>
        <p v-show="btnActived === 99" class="text-gray-600 text-sm">{{ `${formattedDates.start} - ${formattedDates.end}`
        }}</p>
      </div>


    </div>
    <div class="overflow-x-auto lg:overflow-x-hidden">
      <vuetable ref="vuetable" :fields="header" :api-mode="false" :data-manager="dataManager"
        :css="{ tableClass: 'wallet' }" :no-data-template="loading ? '' : 'Nenhum dado retornado'">
        <template v-slot:data="props">
          <div>
            <div v-if="props.rowData.showData || props.rowIndex === 0" class="flex flex-col items-center span_data">
              <span class="leading-snug font-bold" style="font-size: 0.60rem">{{ $moment(props.rowData.data, 'DD/MM/YYYY')
                | moment('DD/MMM') | uppercase }}</span>
              <span class="year text-xs leading-snug ">{{ $moment(props.rowData.data, 'DD/MM/YYYY') |
                moment('YYYY') }}</span>
            </div>

            <p class="label-mobile data-mobile">Data</p>
            <p class="text-left data-mobile">{{ props.rowData.data }}</p>
          </div>
        </template>
        <template v-slot:descricao="props">
          <div>
            <p class="label-mobile">Descrição</p>
            <p class="text-left">{{ props.rowData.descricao }}</p>
          </div>
        </template>
        <template v-slot:tipo="props">
          <div>
            <p class="label-mobile">Tipo</p>
            <p class="text-left">{{ props.rowData.tipo }}</p>
          </div>
        </template> <template v-slot:valor="props">
          <div class="money">
            <p class="label-mobile">Valor</p>
            <p :class="props.rowData.valor < 0 ? 'text-red-500' : 'text-primary'">{{ props.rowData.valor | money }}</p>
          </div>
        </template>
        <template v-slot:saldo="props">
          <div class="money">
            <p class="label-mobile">Saldo</p>
            <p>{{ props.rowData.saldo | money }}</p>
          </div>
        </template>
        <template v-slot:saldoBloqueado="props">
          <div class="money">
            <p class="label-mobile">Saldo bloq.</p>
            <p>{{ props.rowData.saldoBloqueado | money }}</p>
          </div>
        </template>
      </vuetable>

    </div>
    <div class="flex justify-end">
      <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"
        :css="paginationCss"></vuetable-pagination>
    </div>
    <div v-if="loading" class="w-full flex flex-col justify-center items-center mt-4">
      <span class="fa fa-spinner fa-spin text-2xl text-secondary"></span>
    </div>
    <div class="border-t mt-2 flex items-center justify-center lg:justify-end pt-3">
      <button @click="modalDeposit = true"
        class="bg-transparent hover:bg-green-500 text-gray-600 font-semibold hover:text-white py-1 px-2 mr-1 border border-gray-500 text-xs hover:border-transparent rounded">
        Depositar
      </button>

      <button @click="modalWithdrawal = true"
        class="bg-transparent hover:bg-red-500 text-gray-600 font-semibold hover:text-white py-1 px-2 mr-1 border border-gray-500 text-xs hover:border-transparent rounded">
        Sacar
      </button>

      <button @click="$emit('showBoletos')"
        class="bg-transparent hover:bg-blue-500 text-gray-600 font-semibold hover:text-white py-1 px-2 mr-1 border border-gray-500 text-xs hover:border-transparent rounded">
        Meus depósitos
      </button>
    </div>
  </div>
</template>

<script>
import { VuetablePagination } from 'vuetable-2'
import MoneyFilter from '@/mixins/moneyFilter'
import VTextButton from '@/components/Buttons/TextButton'
import WithdrawalModal from '@/components/Investor/Modal/WithdrawalModal'
import DepositModal from '@/components/Investor/Modal/DepositModal'
import Wallet from '@/services/Investor/Wallet/Wallet'
import InvoiceModal from '@/components/Investor/Modal/InvoiceModal'

export default {
  name: 'investor-wallet-table',
  components: { VuetablePagination, VTextButton, WithdrawalModal, DepositModal, InvoiceModal },
  mixins: [MoneyFilter],
  data() {
    return {
      loading: true,
      modalnav: false,
      showInvoiceModal: false,
      invoice: null,
      modalWithdrawal: false,
      modalDeposit: false,
      btnActived: 7,
      dates: { start: '', end: '' },
      transactions: {
        transacoes: []
      },
      pagination: {
        current_page: 0,
        last_page: 0,
        per_page: 20,
        total: 0
      },
      header: [
        {
          name: '__slot:data',
          title: 'Data'
        },
        {
          name: '__slot:descricao',
          title: 'Movimentação'
        },
        {
          name: '__slot:tipo',
          title: 'Tipo'
        },
        {
          name: '__slot:valor',
          title: 'Valor'
        },
        {
          name: '__slot:saldo',
          title: 'Valor Disponível'
        },
        {
          name: '__slot:saldoBloqueado',
          title: 'Valor Bloqueado'
        }
      ],
      paginationCss: {
        wrapperClass: 'border rounded-lg flex m-2 text-secondary',
        activeClass: 'text-primary shadow-inner bg-gray-100 font-bold',
        disabledClass: 'text-gray-300 cursor-not-allowed',
        pageClass: 'pagination_page_class',
        linkClass: 'pagination_page_class',
        icons: {
          first: 'fas fa-angle-double-left',
          prev: 'fas fa-angle-left',
          next: 'fas fa-angle-right',
          last: 'fas fa-angle-double-right'
        }
      }
    }
  },
  computed: {
    contaIuguAtiva() {
      return this.$store.state.investor.contaIuguAtiva
    },
    formattedDates() {
      return {
        start: this.$moment(this.dates.start).format('DD/MM/YYYY'),
        end: this.$moment(this.dates.end).format('DD/MM/YYYY')
      }
    }
  },
  filters: {
    uppercase(value) {
      return value.toUpperCase()
    }
  },
  methods: {
    setInvoice(invoice) {
      this.invoice = invoice;
      this.showInvoiceModal = true;
    },
    onChangePage(page) {
      let reload = false
      switch (true) {
        case page === 'next':
          if (!this.$refs.pagination.isOnLastPage) {
            this.pagination.current_page = this.pagination.current_page + 1
            reload = true
          }
          break
        case page === 'prev':
          if (!this.$refs.pagination.isOnFirstPage) {
            this.pagination.current_page = this.pagination.current_page - 1
            reload = true
          }
          break
        default:
          if (this.pagination.current_page !== page) {
            this.pagination.current_page = page
            reload = true
          }
          break
      }
      if (reload) {
        this.$refs.vuetable.reload()
      }
    },
    dataManager(sortOrder, pagination) {
      if (this.transactions.transacoes.length < 1) return

      const from = (this.pagination.current_page - 1) * this.pagination.per_page
      const to = from + this.pagination.per_page


      return {
        pagination: this.pagination,
        data: this.transactions.transacoes.slice(from, to)
      }
    },
    async getTransactions(days) {
      try {
        this.loading = true
        this.$refs.vuetable.resetData()
        this.btnActived = days || 99
        if (days) {
          this.dates = { start: this.$moment(), end: this.$moment() }
          this.transactions = (await Wallet.getTransactionsByDays(days)).data.data
        } else {
          this.transactions = (await Wallet.getTransactionsByRange(this.formattedDates)).data.data
        }
        const temp = []
        this.transactions.transacoes = this.transactions.transacoes.map(v => {
          if (temp.includes(v.data)) {
            v.showData = false
          } else {
            temp.push(v.data)
            v.showData = true
          }
          return v
        })
        this.pagination = {
          current_page: 1,
          last_page: Math.ceil(this.transactions.transacoes.length / this.pagination.per_page),
          total: this.transactions.transacoes.length,
          per_page: this.pagination.per_page
        }
        this.$refs.vuetable.reload()
        this.$refs.pagination.setPaginationData(this.pagination)

      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted() {
    await this.getTransactions(7)
  }
}
</script>


<style lang="sass" scoped>
.main-content
  @apply w-full bg-white border shadow rounded-lg py-4

.button
  @apply rounded-full bg-white text-gray-600 text-sm shadow-md w-16 h-8
  @screen md
    @apply mr-3
  &:focus
    @apply outline-none
  &_active
    @apply bg-secondary text-white
  &_calendar
    @apply rounded-full bg-white text-gray-600 text-sm shadow-md w-8 h-8 mr-3
    &:focus
      @apply outline-none
    &_active
      @apply bg-secondary text-white

.line
  @apply h-2 w-48
  background: linear-gradient(to right,#ec4c49 0,#f9a021 50%,#73c04f 100%)

.risk
  @apply absolute text-black uppercase text-xss font-semibold z-10 select-none
  top: 100%

</style>

<style lang="sass">
.wallet
  @apply w-full

  .data-mobile
    display: block
    @screen md
      display: none

  .year
    display: block

  .span_data
    width: auto !important
    display: none !important
    @screen md
      display: block !important

  .vuetable-slot
    @apply w-full
    @screen md
      width: fit-content

  .vuetable-slot div
    @apply flex grid grid-cols-3 w-full
    @screen md
      @apply block grid-cols-1


  .money
    @apply flex whitespace-no-wrap
    @screen md
      @apply  items-center justify-center

  .label-mobile
    @apply w-1/4
    font-weight: 500
    @screen md
      @apply hidden

  .text-left
    @apply w-full
    grid-column: span 2/span 2

  thead tr:first-child
    @apply hidden
    @screen md
      @apply table-row

  tr:not(:last-child)
    border-bottom: 1px solid #ccc

  tr
    @apply block m-2 mb-4 pb-4
    @screen md
      display: table-row
      &:nth-child(even)
        @apply bg-gray-100
    th
      @apply p-4 text-xs text-gray-600
      background: #ECECEC
    td
      @apply relative text-gray-600 text-sm  block flex flex-col w-1/2
      @screen md
        @apply border-b border-gray-300 p-4  text-center
        display: table-cell
        width: auto
      &:first-child
        @screen md
          @apply border-white bg-white
          .span_data
            @apply px-2 py-1 absolute inset-x-0 mx-2 top-0 mt-2 rounded-lg
            background-color: rgba(72, 27, 144, 0.2)
      &:nth-child(2)
        @screen md
          @apply border-l

.pagination_page_class
  @apply h-8 w-8 border-l flex justify-center items-center cursor-pointer
  &:first-of-type
    @apply border-l-0 rounded-l-lg
  &:last-of-type
    @apply rounded-r-lg
  &:hover
    @apply bg-gray-100
    &.cursor-not-allowed
      @apply bg-transparent

.money_bag_green
  @apply relative flex justify-center bg-no-repeat bg-center h-10 w-10 cursor-pointer shadow rounded-full bg-primary ml-2
  background-image: url("../../../assets/images/investor_panel/depositar.svg")
  &:hover
    opacity: 0.9
    &>.tooltip
      visibility: visible
  &>.tooltip
    @apply absolute invisible bg-primary text-white text-center text-xss font-semibold rounded-sm whitespace-no-wrap py-1 px-4 z-10 select-none
    bottom: -80%
  &>.tooltip::after
    @apply absolute border-transparent mx-auto
    content: ""
    bottom: 100%
    left: 50%
    transform: rotate(90deg)
    margin-left: -5px
    border-width: 5px
    border-style: solid
    border-right-color: #02B26E
  @screen md
    @apply mr-4 ml-0

.money_bag_red
  @apply relative flex justify-center bg-no-repeat bg-center h-10 w-10 cursor-pointer shadow rounded-full bg-red-600 mr-2
  background-image: url("../../../assets/images/investor_panel/sacar.svg")
  &:hover
    opacity: 0.9
    &>.tooltip
      visibility: visible
  &>.tooltip
    @apply absolute invisible bg-red-600 text-white text-center text-xss font-semibold rounded-sm whitespace-no-wrap py-1 px-4 z-10 select-none
    bottom: -80%
  &>.tooltip::after
    @apply absolute border-transparent mx-auto
    content: ""
    bottom: 100%
    left: 50%
    transform: rotate(90deg)
    margin-left: -5px
    border-width: 5px
    border-style: solid
    border-right-color: #e53e3e
  @screen md
    @apply mr-4


</style>
