<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')"/>
    <div class="container flex justify-center items-center">
      <div class="content">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <img :src="require('@/assets/images/investor_panel/deposit.svg')">
        <p class="title">Entenda como funciona a Carteira!</p>
        <div class="text-center">
          <ul class="subtitle">
            <li>Os repagamentos serão creditados no mesmo dia em que o boleto da parcela do tomador for compensado. O prazo de compensação de boletos é de 1 a 4 dias úteis.</li>
            <li>O Saldo Disponível pode ser usado para reinvestir em novas empresas ou sacar para sua conta bancária.</li>
            <li>Você tem 2 saques gratuitos por mês, os demais serão cobrados com uma taxa de R$ 4,00 por saque.</li>
            <li>Os saques levam até 1 dia útil para ficarem disponíveis em sua conta bancária.</li>
            <li>O valor mínimo para saque é de R$ 5,00.</li>
            <li>Você pode fazer depósitos ilimitados a qualquer momento para compor o saldo da Carteira, o valor mínimo para depósito é de R$ 100,00 e máximo de R$ 10.000,00.</li>
            <li>Os depósitos são feitos através de boletos bancários que podem ser compensados em 3 dias úteis.</li>
            <li>Saldo Bloqueado são transações de aportes que estão em andamento até a formalização na Instituição Bancária.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wallet-info-modal'
}
</script>

<style lang="sass" scoped>

.modal
  @apply fixed flex items-center justify-center w-screen h-screen top-0 left-0 z-50

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full bg-white z-50 flex flex-col justify-center rounded-lg pt-4 px-4
  @screen lg
    @apply w-3/4

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.title
  @apply text-secondary font-bold mb-2 text-center mt-2
  @screen lg
    @apply text-lg

.subtitle
  @apply text-xs opacity-75 text-justify list-disc px-4 my-5
  @screen lg
    @apply text-sm
  li
    @apply leading-loose opacity-75

</style>
