<template>
  <div class="flex flex-col pb-8 lg:flex-row lg:flex-wrap w-full my-2">

    <div class="w-full flex flex-col lg:flex-row">
      <div class="w-full lg:w-7/12 flex flex-col lg:flex-row items-center">
        <p class="title">Minha Carteira</p>
        <v-text-button class="text-secondary text-sm my-3" @click="showInfo = true">Como funciona?</v-text-button>
      </div>
      <div class="w-full lg:w-5/12 flex lg:justify-end justify-center items-center">
        <div class="card">
          <p class="card_title">Saldo Disponível</p>
          <p class="card_subtitle card_subtitle_green">{{ $store.state.investor.balance | money }}</p>
        </div>
        <div class="card">
          <p class="card_title">Saldo Bloqueado</p>
          <p class="card_subtitle card_subtitle_red">{{ blockedBalance | money }}</p>
        </div>
      </div>
    </div>
    <transition name="fade">
      <wallet-info-modal v-show="showInfo" @closeModal="showInfo = false"></wallet-info-modal>
    </transition>
    <transition name="fade">
      <my-boletos-modal v-if="showBoletos" @closeModal="showBoletos = false"></my-boletos-modal>
    </transition>
    <wallet ref="walletRef" @showBoletos="showBoletos = true" />
  </div>
</template>

<script>
import Wallet from '@/components/Investor/Wallet/Wallet'
import WalletInfoModal from '@/components/Investor/Modal/WalletInfoModal'
import MyBoletosModal from '@/components/Investor/Modal/MyBoletosModal'
import VTextButton from '@/components/Buttons/TextButton'
import moneyFilter from '@/mixins/moneyFilter'
import WalletService from '@/services/Investor/Wallet/Wallet'
import { EventBus } from '@/event-bus.js';

export default {
  name: 'investor-wallet',
  components: { Wallet, WalletInfoModal, MyBoletosModal, VTextButton },
  mixins: [moneyFilter],
  data() {
    return {
      showInfo: false,
      showBoletos: false,
      blockedBalance: 0

    }
  },
  created() {
    EventBus.$on('novaTransacaoWallet', this.refresh);
  },
  beforeDestroy() {
    EventBus.$off('novaTransacaoWallet', this.refresh);
  },
  methods: {
    async refresh() {
      await Promise.all([
        this.$refs.walletRef.getTransactions(7),
        this.loadBalances()
      ]);
    },
    async loadBalances() {
      try {
        const [balanceResponse, blockedBalanceResponse] = await Promise.all([
          WalletService.getBalance(),
          WalletService.getBlockedBalance()
        ]);
        this.$store.dispatch('setBalance', balanceResponse.data.data);
        this.blockedBalance = blockedBalanceResponse.data.data;
      } catch (error) {
        console.error('Erro ao carregar saldos:', error);
      }
    },
  },
  watch: {
    showBoletos() {
      if (this.showBoletos) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    },
    showInfo() {
      if (this.showInfo) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    },
  },

  async mounted() {
    try {
      await this.loadBalances();
    } catch (error) {
      console.error(error)
    }
  }
}
</script>

<style lang="sass" scoped>

.title
  @apply font-bold text-xl text-secondary mt-4
  @screen lg
    @apply text-2xl mr-6 my-4

.card
  @apply mx-2 mb-2
  @screen lg
    @apply mr-4
  &_title
    @apply text-xs opacity-75
  &_subtitle
    @apply font-bold
    &_green
      @apply text-primary
    &_red
      @apply text-red-600

</style>
